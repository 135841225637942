import { useEffect, useState, useRef, useContext } from "react";
import styles from "./ResourcesComponent.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { StoreContext } from "../../context/StoreContext";

const ResourcesComponent = (props) => {
 

  const { getEnv } = useContext(StoreContext);

  const [baseURL, setBaseURL] = useState(`https://cdn.stage.remotopro.io`); 
  useEffect(()=>{
  let env = getEnv().then((value) => {setBaseURL(`https://cdn.${value}remotopro.io`)});
  },[baseURL]) 
  //const baseURL = "https://remotopro-public-stage.s3.us-west-1.amazonaws.com"
  const documentKey = {
    "White Paper":
    
    `/web-assets/documents/WhitePaper042024.html`
  };

  const addtlResourcesKey = {
    "Subprocessors List":
    `/web-assets/documents/Subprocessors042024.html`
  };

  const pdfKey = {
    "White Paper":
    `/web-assets/documents/PDF/RemotoPlaybackSecurityWhitePaper.pdf
    `,
    "Subprocessors List":
    `/web-assets/documents/PDF/RemotoPlaybackSubprocessors.pdf`
  };
  

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const doc = query.get('doc');

  const docToDocument = (doc) => {
    if (doc) {
      let words = doc.split("_");
      let capitalized = words.map((word) => word !== 'of' ? word.charAt(0).toUpperCase() + word.slice(1) : word);
      return capitalized.join(" ");
    }
  };

  const [selectedDocument, setSelectedDocument] = useState(doc ? docToDocument(doc) : "White Paper");
  const [documentData, setDocumentData] = useState(documentKey);
  const [addtlResourcesData, setAddtlResourcesData] = useState(addtlResourcesKey);
  const navigate = useNavigate();
  const [query_doc, setQueryDoc] = useState(docToDocument(doc));
  
  // Reference to the iframe element
  const iframeRef = useRef(null);

  // useEffect(() => {
  //   if (query_doc) {
  //     navigate(`/documents`);
  //   }
  // }, [selectedDocument, query_doc, navigate]);

  // Function to download the iframe content as a PDF
  const downloadPDF = () => {
    const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
    
    html2pdf()
      .from(iframeDocument.body)
      .set({
        margin: 1,
        filename: `${selectedDocument}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      })
      .save();
  };

  const renderDoc = (sel_doc) => {
    const url = documentData[sel_doc] || addtlResourcesData[sel_doc];
    return (
      <div className={styles.iFrame}>
        <iframe
          src={`${baseURL}${url}`}
          title="iFrame"
          style={{ display: 'flex', overflow: 'auto', border: '0px', width: "100%", height: "100%" }}
          ref={iframeRef} // Assigning the ref to the iframe element
        />
     
      </div>
    );
  };

  return (
    <div className={styles.legalParent}>
      <h1 className={styles.headerTop}>Resources</h1>
      <div className={styles.horizontal}>
        <div>
          <div className={styles.legalChild}>
            <h1 className={styles.header}>Legal Documents</h1>
            {Object.keys(documentData).map((doc) => (
              <button
                className={(doc === selectedDocument) ? styles.buttonSelected : styles.buttonOff}
                key={doc}
                onClick={() => { setSelectedDocument(doc); }}
              >
                {(doc === "White Paper") ? 'Security White Paper' : doc}
                {doc === selectedDocument && <a id = "downloadPdf"  href = {`${baseURL}${pdfKey[selectedDocument]}`} target = '_blank' download className={styles.downloadPdfButton}>
                    <img style = {{width: '20px'}} src="/icons/download_simple.svg" alt="Download" />
                </a>}
              </button>
            ))}
          </div>
          <div className={styles.legalChild}>
            <h1 className={styles.header}>Additional Resources</h1>
            {Object.keys(addtlResourcesData).map((doc) => (
              <button
                className={(doc === selectedDocument) ? styles.buttonSelected : styles.buttonOff}
                key={doc}
                onClick={() => { setSelectedDocument(doc); }}
              >
                {doc}
                {doc === selectedDocument && <a id = "downloadPdf" href = {pdfKey[selectedDocument]} target = '_blank' download className={styles.downloadPdfButton}>
                    <img style = {{width: '20px'}} src="/icons/download_simple.svg" alt="Download" />
                </a>}
              </button>
            ))}
          </div>
        </div>
        {selectedDocument !== 'none' && renderDoc(selectedDocument)}
      </div>
    </div>
  );
}

export default ResourcesComponent;
