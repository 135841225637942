import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import styles from "./auth_flow.module.css";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../context/StoreContext";


const ConfirmAccountComponent = () => {
  const { env, getEnv, cart, setCart, currentPlan, setCurrentPlan, userToken, setUserToken, userAction, setUserAction, userEmail, setUserEmail, setNotifyObject } = useContext(StoreContext);
  const navigate = useNavigate()
  const [password, setPassword] = useState('')

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  const confirm_account = async (email, code) => {
    const url = `https://api.${env}remotopro.io/v1/auth/signup/confirmation`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'SolutionContext': 'WEB',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        confirmationCode: code,
        solution: 'WEB'
      })
    };

    try {
      const response = await fetch(url, requestOptions);

      const data = await response.json();
      if (!data.token) {
        console.log('error', data)
        if (data.challenge == 'LOGIN'){
          setNotifyObject({
            message: data.message,
            code: 'Success',
            type: 'success'
          })
          setUserEmail(data.email)
          setUserAction('LOGIN')
        }
        else{
        setNotifyObject({
          message: data.description,
          code: 'Error',
          type: 'error'
        })
        }
      }
      if (data.token) {
        setUserToken(data.token)
        setUserAction('STRIPE_HANDOFF')
      }

      // Handle the response data as needed
    } catch (error) {



    }
  };


  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginCard}>
        <img className={styles.logoImage} src={'Remoto PB 1 line.png'} />
        <div style={{ width: '80%' }}>
          <p className={styles.inputLabel} style={{ textAlign: 'center' }}> We have sent a verification code to {userEmail} </p>
          <br />
          <p className={styles.inputLabel} style={{ textAlign: 'center' }}> Enter it below to confirm your email and complete onboarding to REMOTO</p>
          <br />

          <div className={styles.hzLabelContainer}>
            <input
              className={styles.inputContainer}
              type="text"
              value={password}
              onChange={handlePasswordChange}
            />



          </div>
        </div>
        <div className={styles.hzLabelContainer} style={{ justifyContent: 'flex-end', gap: '20px' }}>

          <button className={styles.cancelButton} onClick={() => setUserAction('LOGIN')}> Cancel</button>
          <button
            className={styles.actionButton}
            onClick={(e) => {
              e.preventDefault();
              confirm_account(userEmail, password);
            }}
          >
            Next
          </button>
        </div>
      </div>

    </div>
  )
}
export default ConfirmAccountComponent
