import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import styles from "./auth_flow.module.css";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../context/StoreContext";


const ResetPasswordComponent = () => {
    const { env, cart, setCart, currentPlan, setCurrentPlan, userToken, setUserToken, userAction, setUserAction, setNotifyObject, setUserEmail } = useContext(StoreContext);
    const navigate = useNavigate()
    const [username, setUsername] = useState('')


    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
      };


      const resetPassword = async (email) => {
        const url = `https://api.${env}remotopro.io/v1/auth/password/reset`;
        const requestOptions = {
          method: 'PUT',
          headers: {
            'accept': 'application/json',
            'SolutionContext': 'WEB',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            solution: 'WEB'
          })
        };
      
        try {
          const response = await fetch(url, requestOptions);
            const data = await response.json();
          console.log(JSON.stringify(response))
          if (!response.ok) {
            setNotifyObject({type: 'error', message: data.code.replace(/_/g, ' ') , code: data.status})
            setUserAction('LOGIN')
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          if (data.challenge == 'LOGIN'){
            setNotifyObject({type: 'success', message: 'A temporary password has been sent to your email', code: ''})
            setUserAction('LOGIN')
            return
          }
          
          setUserAction('RESET_PASSWORD')
          setUserEmail(email)
          setNotifyObject({type: 'success', message: 'A reset code has been sent to your email', code: ''})
          // Handle the response data as needed
    
        } catch (error) {

          console.error('There was an error!', error);
        }
      };


    return(
        <div className = {styles.mainContainer}>
            <div className = {styles.loginCard}> 
            <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className={styles.logoImage} src = {'Remoto PB 1 line.png'} /> 
        </div> 
          <h1 style = {{fontSize: '24px'}}> Password reset</h1>
          <p style = {{ textAlign: 'center'}}> Enter your registered email and we'll send you an email with a verification code</p>
                <div style = {{width: '100%'}}>
               
                <p className = {styles.inputLabel}> Email </p>
                <input 
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                    className = {styles.inputContainer} 

                    />
             
                <div className = {styles.hzLabelContainer} style = {{ justifyContent: 'flex-end', gap: '20px'}}> 
                    
                   <button className = {styles.cancelButton} onClick = {()=> setUserAction('LOGIN')}> Cancel</button>
                  
                    <button className={styles.actionButton} onClick={() => resetPassword(username)}> Reset </button>
                    
                </div>
                </div>
            </div>
          
            </div>
    )
}
export default ResetPasswordComponent
