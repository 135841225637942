import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../context/StoreContext";
import TermsComponent from "../../pages/terms_page/terms_component";
import styles from "./auth_flow.module.css";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';


const CreateAccountComponent = () => {
  const { env, setUserAction, setUserEmail, setNotifyObject } = useContext(StoreContext);
  const navigate = useNavigate();
  const [viewTerms, setViewTerms] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(showConfirmPassword => !showConfirmPassword);
  };
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const accountSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    password: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special character.')
      .matches(/[0-9]/, 'Password must contain at least one number.')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),
    acceptTerms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });

  const create_account = async (values) => {
    const { email, password, firstName, lastName, acceptTerms } = values;
    const url = `https://api.${env}remotopro.io/v1/auth/signup`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'SolutionContext': 'WEB',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        solution: 'WEB',
        termAccepted: acceptTerms
      })
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json(); // Always parse the response data
      if (!response.ok) {
        // 409 is a conflict error, meaning the account already exists
        if (data.status == 409) {
          setNotifyObject({ type: 'error', message: 'this account already exists. sign in or check your email to reset your password', code: ''});

          setUserAction('LOGIN')

        }
        else{
        setNotifyObject({ type: 'error', message: data.code.replace(/_/g, ' '), code: '' });
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (data.challenge === 'CONFIRM_EMAIL') {
        setUserAction('CONFIRM_ACCOUNT');
        setUserEmail(data.email);
      }
      // Handle other parts of the response data as needed
    } catch (error) {
      console.error('There was an error!', error);
      //generic error message
      if (error.message !== 'HTTP error! status: 409'){
      setNotifyObject({ type: 'error', message: 'an error has occurred', code: ''});
      }
   
      // Optionally handle the error for the user interface
    }
  };

  const CustomCheckbox = ({ checked, onChange }) => (
    <div className={styles.customCheckbox} onClick={onChange}>
      <div className={checked ? styles.checkboxOn : styles.checkboxOff} style={{ borderRadius: '5px', width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {checked && <img src={'/icons/checkmarkOrange.svg'} style={{ width: '15px', height: '15px' }} />}
      </div>
      {/* <label>I accept the terms and conditions</label> */}
    </div>
  );
  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginCard}>
        <img className={styles.logoImage} src={'Remoto PB 1 line.png'} />
        <Formik
          initialValues={{ email: '', firstName: '', lastName: '', password: '', acceptTerms: false }}
          validationSchema={accountSchema}
          onSubmit={(values, { setSubmitting }) => {
            create_account(values);
            setSubmitting(false);
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className={styles.formContainer}>

              <label className={styles.inputLabel} htmlFor="firstName">First Name</label>

              <Field name="firstName" type="text" className={styles.inputContainer} />
              {errors.firstName && touched.firstName ? <div className={styles.errorText}>{errors.firstName}</div> : null}

              <label className={styles.inputLabel} htmlFor="lastName">Last Name</label>
              <Field name="lastName" type="text" className={styles.inputContainer} />
              {errors.lastName && touched.lastName ? <div className={styles.errorText}>{errors.lastName}</div> : null}
              <label className={styles.inputLabel} htmlFor="email">Email</label>
              <Field name="email" type="text" className={styles.inputContainer} />
              {errors.email && touched.email ? <div className={styles.errorText}>{errors.email}</div> : null}

              <label className={styles.inputLabel} htmlFor="password">Password</label>
              <div className={styles.pwContainer}>

                <Field name="password" type={showPassword ? "text" : "password"} className={styles.inputContainer} />
                <div className={styles.showHidePW} type="button" onClick={togglePasswordVisiblity}>
                  {showPassword ?
                    <img className={styles.showPwIcon} src={'/ic_type=showPass_XXL_, state=default.svg'} /> :
                    <img className={styles.showPwIcon} src={'/ic_type=showPass_XXL_, state=disabled.svg'} />}
                </div>
                {errors.password && touched.password ? <div className={styles.errorText}>{errors.password}</div> : null}
                <label className={styles.inputLabel} htmlFor="confirmPassword">Confirm Password</label>
              </div>
              <div className={styles.pwContainer}>

                <Field name="confirmPassword" type={showConfirmPassword ? "text" : "password"} className={styles.inputContainer} />
                <div className={styles.showHidePW} type="button" onClick={toggleConfirmPasswordVisiblity}>
                  {showConfirmPassword ?
                    <img className={styles.showPwIcon} src={'/ic_type=showPass_XXL_, state=default.svg'} /> :
                    <img className={styles.showPwIcon} src={'/ic_type=showPass_XXL_, state=disabled.svg'} />}
                </div>

              </div>
              {errors.confirmPassword && touched.confirmPassword ? <div className={styles.errorText}>{errors.confirmPassword}</div> : null}

              <label className={styles.hzLabelContainer}>
                <div className={styles.errorContainer}>
                  <CustomCheckbox
                    checked={values.acceptTerms}
                    onChange={() => setFieldValue('acceptTerms', !values.acceptTerms)}

                  />

                  <p style={{ fontSize: '14px' }}> I have read and agree to Remoto's <a style={{ padding: '0px 10px 0px 5px' }} onClick={() => setViewTerms(true)}> Terms and Conditions</a> </p>
                </div>
              </label>

              {errors.acceptTerms && touched.acceptTerms ? <div className={styles.errorText}>{errors.acceptTerms}</div> : null}

              <div className={styles.hzLabelContainer} style={{ paddingTop: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
                <p>
                  Already have an account? <a onClick={() => { setUserAction('LOGIN') }}> Sign in </a>
                </p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <button className={styles.cancelButton} onClick={() => navigate(-1)}> Back</button>


                  <button type="submit" className={styles.actionButton} >Sign Up</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

      </div>
      {viewTerms &&
        <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '10vh', width: '80vw' }}>
          <button style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', alignSelf: 'end', textAlign: 'center', backgroundColor: '#FB6520', color: '#202020' }} onClick={() => { setViewTerms(false) }}>close</button>
          <div className={styles.termsFrame} >
            <TermsComponent />
            {/* <button style={{ textAlign: 'center', marginTop: '-100px' }} onClick={() => { setViewTerms(false) }}>Close</button> */}
          </div>
        </div>
      }
    </div>
  );

}
export default CreateAccountComponent
